<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between" horizontal>
        <q-card-section>
          <p class="q-pb-none">
            <strong>Nama Tahun Ajaran :</strong>
          </p>
          <div class="row">
            <q-input
              outlined
              type="number"
              v-model="tahunAjar.nama.depan"
              :readonly="$route.params.id != -1"
              style="max-width:100px"
            ></q-input>
            <span class="q-mx-sm" style="font-size:40px">/</span>
            <q-input
              outlined
              type="number"
              v-model="tahunAjar.nama.belakang"
              :readonly="$route.params.id != -1"
              style="max-width:100px"
            ></q-input>
          </div>
        </q-card-section>
        <q-card-section>
          <p>
            <strong>Semester :</strong>
          </p>
          <q-input
            outlined
            type="number"
            v-model="tahunAjar.semester"
            :readonly="$route.params.id != -1"
            style="max-width:100px"
          ></q-input>
        </q-card-section>
        <q-card-section>
          <p class="q-pb-none">
            <strong>Awal Tahun Ajaran :</strong>
          </p>
          <q-input
            :disable="!isTahunAjaranReady"
            :readonly="true"
            filled
            v-model="tahunAjar.tanggal_mulai"
            mask="date"
            :rules="['date']"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    :readonly="$route.params.id != -1"
                    :options="maxMinDate"
                    v-model="tahunAjar.tanggal_mulai"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </q-card-section>
        <q-card-section>
          <p class="q-pb-none">
            <strong>Akhir Tahun Ajaran :</strong>
          </p>
          <q-input
            :disable="!isTahunAjaranReady"
            :readonly="true"
            filled
            v-model="tahunAjar.tanggal_selesai"
            mask="date"
            :rules="['date']"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    :readonly="$route.params.id != -1"
                    :options="maxMinDate"
                    v-model="tahunAjar.tanggal_selesai"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </q-card-section>
      </q-card-section>
      <q-markup-table flat bordered class="stickyTable">
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>Pekan ke-</th>
            <th>awal pekan</th>
            <th>akhir pekan</th>
          </tr>
        </thead>
        <tbody v-if="$route.params.id == -1" class="bg-indigo-1">
          <tr v-for="(val, i) in weeks" :key="i">
            <td>{{ i + 1 }}</td>
            <td v-if="val.start._d">
              {{ val.start._d | moment("DD-MMM-YYYY") }}
            </td>
            <td v-if="val.end._d">{{ val.end._d | moment("DD-MMM-YYYY") }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(val, i) in weeks" :key="i">
            <td>{{ i + 1 }}</td>
            <td v-if="val.awal">{{ val.awal | moment("DD-MMM-YYYY") }}</td>
            <td v-if="val.akhir">{{ val.akhir | moment("DD-MMM-YYYY") }}</td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions class="row justify-between">
        <q-btn
          flat
          color="primary"
          label="BACK"
          @click="$router.go(-1)"
        ></q-btn>
        <q-btn
          outline
          color="primary"
          label="TAMBAH LIBUR"
          v-if="$route.params.id != -1"
          :to="'/pengaturanharilibur/' + $route.params.id"
        ></q-btn>
        <q-btn
          unelevated
          color="primary"
          label="SIMPAN TAHUN AJARAN"
          v-can="['ADMIN', 'AKADEMIK']"
          v-if="$route.params.id == -1"
          @click="simpan"
          :disable="this.weeks.length == 0"
        ></q-btn>
        <q-btn
          unelevated
          color="positive"
          label="AKTIFKAN"
          v-can="['ADMIN', 'AKADEMIK']"
          v-else-if="$route.params.id != -1 && tahunAjar.is_active == 0"
          @click="activate"
        ></q-btn>
        <q-btn
          unelevated
          color="negative"
          label="NON-AKTIFKAN"
          v-can="['ADMIN', 'AKADEMIK']"
          v-else-if="$route.params.id != -1 && tahunAjar.is_active == 1"
          @click="deactivate"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);
export default {
  data() {
    return {
      active: true,
      tahunAjar: {
        nama: {
          depan: "",
          belakang: "",
        },
        semester: 1,
        tanggal_mulai: null,
        tanggal_selesai: null,
        is_active: 0,
      },
      weeks: [],
    };
  },

  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    let cYear = moment().year();
    this.tahunAjar.nama.depan = cYear;
    this.tahunAjar.nama.belakang = cYear + 1;
    if (this.$route.params.id != -1) {
      await this.getTahunAjarDetail();
      await this.getWeekfromDB();
      // this.getWeekNumber();
    }
    moment.locale("ID");
    moment.updateLocale("ID", {
      week: {
        dow: 6, // First day of week is Saturday
        doy: 12, // First week of year must contain 1 January (7 + 6 - 1)
      },
    });
    this.$q.loading.hide();
  },
  watch: {
    tahunAjar: {
      deep: true,
      handler(val) {
        let result =
          this.tahunAjar.nama.depan &&
          this.tahunAjar.nama.belakang &&
          this.tahunAjar.semester &&
          this.tahunAjar.tanggal_mulai &&
          this.tahunAjar.tanggal_selesai;
        if (result) {
          this.getWeekNumber();
        }
      },
    },
  },
  computed: {
    isTahunAjaranReady() {
      return this.tahunAjar.nama.depan && this.tahunAjar.nama.belakang;
    },
  },
  methods: {
    async getWeekfromDB() {
      let resp = await this.$http.get(
        `/pengaturan/tahun_ajaran/getweeks/${this.$route.params.id}`
      );
      this.weeks = resp.data;
    },
    maxMinDate(date) {
      return (
        date >= `${this.tahunAjar.nama.depan}/01/01` &&
        date <= `${this.tahunAjar.nama.belakang}/12/31`
      );
    },
    getWeekNumber() {
      let a = moment(this.tahunAjar.tanggal_mulai, "YYYY/MM/DD");
      let b = moment(this.tahunAjar.tanggal_selesai, "YYYY/MM/DD");
      let firstDay = moment(a);
      let endDay = moment(b);
      if (a > b) return;
      let monthRange = moment.range(firstDay, endDay);
      let weeks = [];
      for (let m of monthRange.by("days")) {
        if (
          weeks.find((el) => {
            return m.week() == el.w && m.weekYear() == el.y;
          }) == undefined
        ) {
          let w = m.week();
          let y = m.weekYear();
          let temp = {
            w: w,
            y: y,
          };

          weeks.push(temp);
        }
      }
      let calendar = [];
      let idx = 0;
      for (let week of weeks) {
        let firstWeekDay = moment()
          .year(week.y)
          .week(week.w)
          .weekday(0);
        let lastWeekDay = moment()
          .year(week.y)
          .week(week.w)
          .weekday(6);
        let weekRange = moment.range(firstWeekDay, lastWeekDay);
        if (idx == 0) weekRange = moment.range(a, lastWeekDay);
        if (idx == weeks.length - 1) {
          weekRange = moment.range(firstWeekDay, b);
        }
        calendar.push(weekRange);
        idx++;
      }
      this.weeks = calendar;
    },
    async getTahunAjarDetail() {
      let resp = await this.$http.get(
        `/pengaturan/tahun_ajaran/getsingle/${this.$route.params.id}`
      );
      let temp = {
        depan: "",
        belakang: "",
      };
      let tempNama = resp.data.nama.split("/");
      temp.depan = tempNama[0];
      temp.belakang = tempNama[1];

      resp.data.nama = temp;
      this.tahunAjar = resp.data;
    },
    async simpan() {
      this.tahunAjar.jenjang = this.$route.params.jenjang;
      this.tahunAjar.nama = `${this.tahunAjar.nama.depan}/${this.tahunAjar.nama.belakang}`;

      let weeks = [];
      for (let w of this.weeks) {
        let temp = {
          awal: moment(w.start._d).format("YYYY-MM-DD"),
          akhir: moment(w.end._d).format("YYYY-MM-DD"),
        };
        weeks.push(temp);
      }

      this.tahunAjar.weeks = weeks;
      this.tahunAjar.jumlah_minggu = weeks.length;

      await this.$http.post(`/pengaturan/tahun_ajaran/add`, this.tahunAjar);
      this.$q.notify({
        message: `Tahun Ajaran ${this.tahunAjar.nama} Semester ${this.tahunAjar.semester} pada ${this.tahunAjar.jenjang} Telah di Tambahkan`,
        color: "positive",
      });
      this.$router.push(
        `/pengaturanlisttahunajaran/${this.$route.params.jenjang}`
      );
    },
    async activate() {
      await this.$http.put(
        `/pengaturan/tahun_ajaran/activate/${this.tahunAjar.id}/${this.tahunAjar.jenjang}`
      );
      this.$q.notify({
        message: `Tahun Ajaran ${this.tahunAjar.nama.depan}/${this.tahunAjar.nama.belakang} Semester ${this.tahunAjar.semester} pada ${this.tahunAjar.jenjang} Telah di Aktifkan`,
        color: "positive",
      });
      this.$router.push(
        `/pengaturanlisttahunajaran/${this.$route.params.jenjang}`
      );
    },
    async deactivate() {
      await this.$http.put(
        `/pengaturan/tahun_ajaran/deactivate/${this.tahunAjar.id}`
      );
      this.$q.notify({
        message: `Tahun Ajaran ${this.tahunAjar.nama.depan}/${this.tahunAjar.nama.belakang} Semester ${this.tahunAjar.semester} pada ${this.tahunAjar.jenjang} Telah di Aktifkan`,
        color: "negative",
      });
      this.$router.push(
        `/pengaturanlisttahunajaran/${this.$route.params.jenjang}`
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
